import React, { useEffect, useRef, useState } from "react";
import "../App.css";
import Footer from "./Footer";
import Modal from "./Modal";
import VisionToLife from "./VisionToLife";
import VirtualTour from "./VirtualTour";
import Renders from "./Renders";
import YouOwnApp from "./YouOwnApp";
import Animation from "./Animation";
import Jser from "./Jser";
import Tooltip from "./Tooltip";
import ImageCarousel from "./ImageCarousel";
import HeroSection from "./HeroSection";
import Navbar from "./Navbar";
const renderImages = [
  "render1.jpeg",
  "render2.jpeg",
  "render3.jpeg",
  "render4.jpeg",
  "render5.jpeg",
  "render6.jpeg",
  "render7.jpeg",
  "render8.jpeg",
  "render9.jpeg",
  "render10.jpeg",
  "render12.jpeg",
  "render14.jpeg",
  "render15.jpeg",
  "render16.jpeg",
  "render17.jpeg",
  "render18.jpeg",
  "render19.png",
  "render20.png",
  "render21.png",
  "render22.png",
  "render23.png",
  "render24.png",
  "render25.png",
  "render26.png",
  "render27.png",
  "render28.png",
  "render31.png",
  "render33.jpeg",
  "render34.jpeg",
  "render35.jpeg",
  "render36.png",
  "render37.png",
  "render38.png",
  "render39.png",
  "render40.jpeg",
  "render41.jpeg",
  "render42.jpeg",
  "render43.jpeg",
  "render44.jpeg",
  "render45.jpeg",
  "render46.jpeg",
];

const landingCarousel = [
  {
    type: "image",
    image: "/images/cinematics.png",
    header: "High-Impact Animations",
    description:
      "Engage clients with cinematic walkthroughs that highlight every detail of your project.",
    title: "High-Impact Animations",
    btnName: "ENQUIRE NOW",
    backgroundColor: "#393433",
    link: "#cinematic",
  },
  {
    type: "image",
    image: "/images/360-tour.png",
    header: "Interactive Virtual Tours",
    description:
      "Allow clients to explore spaces freely with immersive 360° tours.",
    title: "Interactive Virtual Tours",
    btnName: "DISCOVER MORE",
    backgroundColor: "#97897b",
    link: "#360-tour",
  },
  {
    type: "image",
    image: "/images/render.png",
    header: "Photorealistic Renderings",
    description:
      "Inspire confidence with high-definition, lifelike images that showcase every corner of your property.",
    title: "Photorealistic Renderings",
    btnName: "DISCOVER MORE",
    backgroundColor: "#efa32a",
    link: "#renders",
  },
  {
    type: "image",
    image: "/images/custom-app.png",
    header: "Custom Interactive Apps",
    description:
      "Deepen client engagement with tailored digital experiences designed to highlight your project’s unique features.",
    title: "Custom Interactive Apps",
    btnName: "DISCOVER MORE",
    backgroundColor: "#b99167",
    link: "#custom-apps",
  },
  {
    type: "video",
    image: "/images/jamie.png",
    header: "3D Architectural Visuals that Sell Off-Plan properties Faster",
    description:
      "Best in class, 3D animation, 360 Virtual Tours  and Renders that captivate buyers and accelerate sales.",
    title: "Jamie Durie Presents",
    btnName: "DISCOVER MORE",
    backgroundColor: "#c7eced",
    link: "#vision-to-life",
  },
];

const Home = ({ t, currentLanguage }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const [isModalJenOpen, setIsModalJenOpen] = useState(false);
  const [discoverMore, setDiscoverMore] = useState({
    jser: false,
    vision: false,
    animation: false,
    virtualTour: false,
    renders: false,
    app: false,
  });
  const [isIframeLoading, setIsIframeLoading] = useState(true);
  const [isRenderOpen, setIsRenderOpen] = useState(false);
  const [isVisibleScrollToTop, setIsVisibleScrollToTop] = useState(false);

  const handleIframeLoad = () => {
    setIsIframeLoading(false);
  };

  useEffect(() => {
    if (!isTourOpen) {
      setIsIframeLoading(true);
    }
  }, [isTourOpen]);

  useEffect(() => {
    if (isModalOpen) {
      document.getElementById("backgroundVideo").pause();
    }
  }, [isModalOpen]);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 400) {
        setIsVisibleScrollToTop(true);
      } else {
        setIsVisibleScrollToTop(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  useEffect(() => {
    const sections = document.querySelectorAll(".fade-in-section");
    const banners = document.querySelectorAll(".animate-slide-in-left");

    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("show");
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.15 }
    );
    banners.forEach((banner) => observer.observe(banner));
    sections.forEach((section) => observer.observe(section));

    // Cleanup on unmount
    return () => {
      sections.forEach((section) => observer.unobserve(section));
      banners.forEach((banner) => observer.unobserve(banner));
    };
  }, []);

  const sectionRef = {
    heroSection: useRef(null),
    next: useRef(null),
    vision: useRef(null),
    animation: useRef(null),
    virtualTour: useRef(null),
    render: useRef(null),
    ownApp: useRef(null),
  };
  const scrollToNextSection = (nextSection) => {
    sectionRef[nextSection].current.scrollIntoView({ behavior: "smooth" });
  };

  const showDiscoverMore = (item) => {
    setDiscoverMore({ ...discoverMore, [item]: true });
  };

  return (
    <>
      {isVisibleScrollToTop && (
        <Navbar scrollToNextSection={scrollToNextSection} />
      )}
      <main>
        <HeroSection
          sectionRef={sectionRef}
          currentLanguage={currentLanguage}
          scrollToNextSection={scrollToNextSection}
          t={t}
        />

        <section className="mt-28 md:mt-16 pt-0 md:py-8" ref={sectionRef.next}>
          <div className="fade-in-section relative text-center">
            <h1 className="header text-2xl md:text-4xl text-black tracking-widest text-center">
              {t("section1.header")}
            </h1>
            <p className="text-p uppercase pt-8">{t("section1.subHeader")}</p>
            <div className="section-entry">
              <p className="text-p-s pt-6 pb-0">{t("section1.description")}</p>
            </div>
          </div>
          <div className="pb-10" />
        </section>

        <section className="mt-4 mb-4 md:mb-16">
          <div className="shell">
            <div className="fade-in-section">
              <div
                className={`grid ${
                  currentLanguage === "en"
                    ? "grid-cols-1  md:grid-cols-3 lg:grid-cols-5"
                    : "grid-cols-1   md:grid-cols-3 lg:grid-cols-6"
                } gap-2`}
              >
                {currentLanguage === "zh" && (
                  <div className="flex flex-col border-top pt-5">
                    {discoverMore.jser && (
                      <div className="mb-4 md:mb-8">
                        <Jser
                          setIsModalOpen={setIsModalJenOpen}
                          show={true}
                          t={t}
                        />
                      </div>
                    )}
                    {!discoverMore.jser && (
                      <div className="flex flex-col h-full mb-4 md:mb-0">
                        <div className="flex-row-between">
                          <p className="title uppercase">
                            {t("section0.header")}
                          </p>
                        </div>
                        <img
                          src="/images/jsher.png"
                          className="my-5 h-full object-cover"
                          alt={t("section0.header")}
                        />
                        <div
                          onClick={() => showDiscoverMore("jser")}
                          className="btn btn-jamie rounded-bl-3xl rounded-tr-3xl md:rounded-bl-3xl md:rounded-tr-none block md:hidden"
                        >
                          {t("discoverMore")}
                        </div>
                        <a
                          href="#jennifer"
                          className="btn btn-jamie rounded-bl-3xl rounded-tr-3xl md:rounded-bl-3xl md:rounded-tr-none hidden md:block uppercase"
                        >
                          {t("discoverMore")}
                        </a>
                      </div>
                    )}
                  </div>
                )}
                <div className="flex flex-col border-top pt-5">
                  {discoverMore.vision && (
                    <div className="mb-4 md:mb-8" id="jamie-durie-presents">
                      <VisionToLife
                        setIsModalOpen={setIsModalOpen}
                        show={true}
                        t={t}
                      />
                    </div>
                  )}
                  {!discoverMore.vision && (
                    <div className="flex flex-col h-full mb-4 md:mb-0">
                      <div className="flex-row-between relative w-full z-[999]">
                        <Tooltip text={t("sectionLabel.tooltip")}>
                          <p className="title uppercase">
                            {t("sectionLabel.jamie")}
                          </p>
                        </Tooltip>
                      </div>
                      <img
                        src="/images/jamie.png"
                        className="my-5 h-full object-cover"
                        alt={t("sectionLabel.jamie")}
                      />
                      <div
                        onClick={() => showDiscoverMore("vision")}
                        className="btn btn-jamie rounded-bl-3xl rounded-tr-3xl md:rounded-bl-3xl md:rounded-tr-none block md:hidden"
                      >
                        {t("discoverMore")}
                      </div>
                      <a
                        href="#vision-to-life"
                        className={`btn btn-jamie ${
                          currentLanguage === "zh"
                            ? "md:rounded-none rounded-bl-3xl rounded-tr-3xl"
                            : "rounded-bl-3xl rounded-tr-3xl md:rounded-bl-3xl md:rounded-tr-none"
                        } hidden md:block uppercase`}
                      >
                        {t("discoverMore")}
                      </a>
                    </div>
                  )}
                </div>
                <div className="flex flex-col border-top pt-5">
                  {discoverMore.animation && (
                    <div className="mb-4 md:mb-8" id="immersive-animation">
                      <Animation
                        setIsVideoOpen={setIsVideoOpen}
                        show={true}
                        t={t}
                      />
                    </div>
                  )}

                  {!discoverMore.animation && (
                    <div className="flex flex-col mb-4 md:mb-0">
                      <div className="flex-row-between">
                        <p className="title uppercase">
                          {t("sectionLabel.animation")}
                        </p>
                      </div>
                      <img
                        src="/images/cinematics.png"
                        className="my-5 h-full object-cover"
                        alt={t("sectionLabel.animation")}
                      />
                      <div
                        onClick={() => showDiscoverMore("animation")}
                        className="btn btn-jamie rounded-bl-3xl rounded-tr-3xl md:rounded-bl-3xl md:rounded-tr-none block md:hidden"
                      >
                        {t("discoverMore")}
                      </div>
                      <a
                        href="#cinematic"
                        className="btn md:rounded-none rounded-bl-3xl rounded-tr-3xl hidden md:block uppercase"
                      >
                        {t("discoverMore")}
                      </a>
                    </div>
                  )}
                </div>
                <div className="flex flex-col border-top pt-5">
                  {discoverMore.virtualTour && (
                    <div
                      className="mb-4 md:mb-8"
                      id="interactive-virtual-tours"
                    >
                      <VirtualTour
                        setIsTourOpen={setIsTourOpen}
                        show={true}
                        t={t}
                      />
                    </div>
                  )}

                  {!discoverMore.virtualTour && (
                    <div className="flex flex-col mb-4 md:mb-0 h-full">
                      <div className="flex-row-between">
                        <p className="title uppercase">
                          {t("sectionLabel.virtualTour")}
                        </p>
                      </div>
                      <img
                        src="/images/360-tour.png"
                        className="my-5 h-full object-cover"
                        alt={t("sectionLabel.virtualTour")}
                      />
                      <div
                        onClick={() => showDiscoverMore("virtualTour")}
                        className="btn btn-jamie rounded-bl-3xl rounded-tr-3xl md:rounded-bl-3xl md:rounded-tr-none block md:hidden"
                      >
                        {t("discoverMore")}
                      </div>
                      <a
                        href="#360-tour"
                        className="btn md:rounded-none rounded-bl-3xl rounded-tr-3xl hidden md:block uppercase"
                      >
                        {t("discoverMore")}
                      </a>
                    </div>
                  )}
                </div>
                <div className="flex flex-col border-top pt-5">
                  {discoverMore.renders && (
                    <div className="mb-4 md:mb-8" id="photorealistic-render">
                      <Renders
                        show={true}
                        t={t}
                        setIsRenderOpen={setIsRenderOpen}
                      />
                    </div>
                  )}
                  {!discoverMore.renders && (
                    <div className="flex flex-col mb-4 md:mb-0 h-full">
                      <div className="flex-row-between">
                        <p className="title uppercase">
                          {t("sectionLabel.renders")}
                        </p>
                      </div>
                      <img
                        src="/images/render.png"
                        className="my-5 h-full object-cover"
                        alt={t("sectionLabel.renders")}
                      />
                      <div
                        onClick={() => showDiscoverMore("renders")}
                        className="btn btn-jamie rounded-bl-3xl rounded-tr-3xl md:rounded-bl-3xl md:rounded-tr-none block md:hidden"
                      >
                        {t("discoverMore")}
                      </div>
                      <a
                        href="#renders"
                        className="btn md:rounded-none rounded-bl-3xl rounded-tr-3xl hidden md:block uppercase"
                      >
                        {t("discoverMore")}
                      </a>
                    </div>
                  )}
                </div>
                <div className="flex flex-col border-top pt-5">
                  {discoverMore.app && (
                    <div className="mb-4 md:mb-8" id="your-own-app">
                      <YouOwnApp show={true} t={t} />
                    </div>
                  )}
                  {!discoverMore.app && (
                    <div className="flex flex-col h-full">
                      <div className="flex-row-between">
                        <p className="title uppercase">
                          {t("sectionLabel.yourOwnApp")}
                        </p>
                      </div>
                      <img
                        src="/images/custom-app.png"
                        className="my-5 h-full object-cover"
                        alt={t("sectionLabel.yourOwnApp")}
                      />
                      <div
                        onClick={() => showDiscoverMore("app")}
                        className="btn btn-jamie rounded-bl-3xl rounded-tr-3xl md:rounded-bl-3xl md:rounded-tr-none block md:hidden"
                      >
                        {t("discoverMore")}
                      </div>
                      <a
                        href="#custom-apps"
                        className="btn rounded-bl-3xl rounded-tr-3xl md:rounded-tr-3xl md:rounded-bl-none hidden md:block uppercase"
                      >
                        {t("discoverMore")}
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="pb-0 md:pb-8" />
        </section>

        {currentLanguage === "zh" && (
          <section className="my-16 hidden md:block">
            <Jser
              setIsModalOpen={setIsModalJenOpen}
              show={true}
              t={t}
              scrollToNextSection={scrollToNextSection}
            />
            <div className="pb-8" />
          </section>
        )}

        <section
          className="my-16 hidden md:block"
          id="jamie-durie-presents"
          ref={sectionRef.vision}
        >
          <VisionToLife
            setIsModalOpen={setIsModalOpen}
            t={t}
            scrollToNextSection={scrollToNextSection}
          />
          <div className="pb-8" />
        </section>

        <section
          className="my-16 hidden md:block"
          id="immersive-animation"
          ref={sectionRef.animation}
        >
          <Animation
            setIsVideoOpen={setIsVideoOpen}
            t={t}
            scrollToNextSection={scrollToNextSection}
          />
          <div className="pb-8" />
        </section>

        <section
          className="my-16 hidden md:block"
          id="interactive-virtual-tours"
          ref={sectionRef.virtualTour}
        >
          <VirtualTour
            setIsTourOpen={setIsTourOpen}
            t={t}
            scrollToNextSection={scrollToNextSection}
          />
          <div className="pb-8" />
        </section>

        <section
          className="my-16 hidden md:block"
          id="photorealistic-renderings"
          ref={sectionRef.render}
        >
          <Renders
            t={t}
            scrollToNextSection={scrollToNextSection}
            setIsRenderOpen={setIsRenderOpen}
          />
          <div className="pb-8" />
        </section>

        <section
          className="my-16 hidden md:block"
          id="your-own-app"
          ref={sectionRef.ownApp}
        >
          <YouOwnApp t={t} scrollToNextSection={scrollToNextSection} />
          <div className="pb-8" />
        </section>

        <Footer t={t} />
      </main>
      {isVisibleScrollToTop && (
        <div
          className="h-12 w-12 rounded-full bg-gray-1 fixed right-5 bottom-5 p-3 cursor-pointer z-50 opacity-50"
          onClick={() => scrollToNextSection("heroSection")}
        >
          <img
            src="/icons/down-arrow.svg"
            alt="Down Arrow"
            height={10}
            className="rotate-180"
          />
        </div>
      )}

      <Modal isOpen={isModalJenOpen} setIsOpen={setIsModalJenOpen}>
        <video
          autoPlay={true}
          playsInline={true}
          muted={false}
          controls={true}
          loop={true}
          className="w-full watch-video"
        >
          <source
            src={`${process.env.REACT_APP_BASE_URL}/theme/dashboard/jennifer.mp4`}
            type="video/mp4"
          />
        </video>
      </Modal>

      <Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen}>
        <video
          autoPlay={true}
          playsInline={true}
          muted={false}
          controls={true}
          loop={true}
          className="w-full watch-video"
        >
          <source
            src={`${process.env.REACT_APP_BASE_URL}/theme/dashboard/watch-video.mp4`}
            type="video/mp4"
          />
        </video>
      </Modal>

      <Modal isOpen={isVideoOpen} setIsOpen={setIsVideoOpen}>
        <video
          autoPlay={true}
          playsInline={true}
          muted={false}
          controls={true}
          loop={true}
          className="w-full watch-video"
        >
          <source
            src={`${process.env.REACT_APP_BASE_URL}/theme/dashboard/showcase-latest.mp4`}
            type="video/mp4"
          />
        </video>
      </Modal>

      <Modal isOpen={isTourOpen} setIsOpen={setIsTourOpen}>
        {isIframeLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-200 bg-opacity-75">
            <div className="w-12 h-12 border-4 border-t-transparent border-white rounded-full animate-spin"></div>
          </div>
        )}
        <iframe
          src="https://storage.net-fs.com/hosting/7566552/23/"
          onLoad={handleIframeLoad}
          title="2 Bed MRP North Aspect"
          width="100%"
          height="100%"
          allow="fullscreen"
        ></iframe>
      </Modal>

      <Modal isOpen={isRenderOpen} setIsOpen={setIsRenderOpen}>
        <div className="h-full" id="renderModal">
          <ImageCarousel images={renderImages} slideCount={1}>
            {renderImages.map((image, index) => (
              <div key={index}>
                <img
                  src={`/images/renders/${image}`}
                  alt={`Slide ${image}`}
                  className="w-full h-auto"
                />
              </div>
            ))}
          </ImageCarousel>
        </div>
      </Modal>
    </>
  );
};

export default Home;
