import React, { useState } from "react";
import { Link } from "react-router-dom";

const HeroSection = ({
  sectionRef,
  currentLanguage,
  scrollToNextSection,
  t,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const handleLoadedData = () => {
    setIsLoading(false);
  };

  const widthClass = () => {
    return currentLanguage === "en"
      ? "w-[87%] md:w-[70%] m-auto flex flex-col gap-5 list-none mt-8 mb-10 slidebar-box-text slidebar-box slidebar-box-animate-info"
      : "w-[87%] md:w-[50%] m-auto flex flex-col gap-5 list-none mt-6 mb-10 slidebar-box-text slidebar-box slidebar-box-animate-info";
  };

  const headerClass = () => {
    return currentLanguage === "en"
      ? "slidebar-box-h1 slidebar-box slidebar-box-animate-header uppercase text-left md:text-center"
      : "text-4xl font-bold slidebar-box slidebar-box-animate-header uppercase text-left md:text-center pb-2";
  };

  const subHeaderClass = () => {
    return currentLanguage === "en"
      ? "slidebar-box-h1 slidebar-box-h2 slidebar-box slidebar-box-animate-header uppercase border-b border-black pb-8 text-left md:text-center"
      : "text-2xl slidebar-box slidebar-box-animate-header uppercase border-b border-black pb-8 text-left md:text-center";
  };

  return (
    <section
      id="hero"
      className="bg-gray-100 relative"
      ref={sectionRef.heroSection}
    >
      <div className="relative carousel-full-screen">
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-200 bg-opacity-75">
            <div className="w-12 h-12 border-4 border-t-transparent border-white rounded-full animate-spin"></div>
          </div>
        )}
        <video
          onLoadedData={handleLoadedData}
          autoPlay={true}
          playsInline={true}
          muted={true}
          controls={false}
          loop={true}
          id="backgroundVideo"
          className="w-full"
        >
          <source
            src={`${process.env.REACT_APP_BASE_URL}/theme/dashboard/background_latest.mp4`}
            type="video/mp4"
          />
        </video>
        <div className="scroll" onClick={() => scrollToNextSection("next")}>
          <img
            src="/icons/down-arrow.svg"
            alt="Down Arrow"
            height={40}
            className="arrow-down"
          />
        </div>

        <div class="intro hero">
          <h1 className="intro-title">{t("hero.header")}</h1>
          <Link to="/start-your-project">
            <button className="rounded-bl-3xl rounded-tr-3xl border border-white  text-white mt-5 p-2 lg:py-4 lg:px-5 text-base lg:text-2xl hover:bg-white hover:text-black">
              {t("startProject")}
            </button>
          </Link>
        </div>
        {/* <div className="slidebar-overlay slidebar-box-animate">
          <div className="slidebar-wrapper relative">
            <div>
              <img
                srcSet="/images/logo-black-small.png 50w, /images/logo-black.png 200w"
                sizes="(max-width: 600px) 50px, (max-width: 1200px) 200px"
                src="/images/logo-black.png"
                loading="lazy"
                alt="Logo"
                className={`h-[50px] md:h-[65px] ml-4 md:m-auto  mb-14 md:mb-14 mt-12 md:mt-8`}
              />

              <div className="px-4 md:px-8">
                <h1 className={headerClass()}>{t("sidebar.header")}</h1>

                <h2 className={subHeaderClass()}>{t("sidebar.subHeader")}</h2>

                <ul className={`${widthClass()}`}>
                  <li className="relative">
                    <span className="absolute text-[0.5rem] left-[-6%] top-1/2 transform -translate-y-1/2 text-black">
                      &#x25C6;
                    </span>
                    <span>{t("sidebar.list1")}</span>
                  </li>
                  <li className="relative">
                    <span className="absolute  text-[0.5rem] left-[-6%] top-1/2 transform -translate-y-1/2 text-black">
                      &#x25C6;
                    </span>
                    {t("sidebar.list2")}
                  </li>
                  <li className="relative ">
                    <span className="absolute  text-[8px] left-[-6%] top-1/2 transform -translate-y-1/2 text-black">
                      &#x25C6;
                    </span>
                    {t("sidebar.list3")}
                  </li>
                </ul>
              </div>

              <img
                className={`slidebar-box hidden md:block slidebar-box-animate-media`}
                alt="Landing"
                src="/images/landing.gif"
                style={{
                  height: "250px",
                  width: "100%",
                  objectFit: "fill",
                }}
              />

              <video
                autoPlay={true}
                playsInline={true}
                muted={true}
                controls={false}
                loop={true}
                className="block md:hidden slidebar-box-animate-media"
                style={{
                  minHeight: "auto",
                  height: "40vh",
                  margin: "auto",
                }}
              >
                <source
                  src={`${process.env.REACT_APP_BASE_URL}/theme/dashboard/background_latest.mp4`}
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default HeroSection;
