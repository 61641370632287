import { useState, useEffect } from "react";

const Navbar = () => {
  const [activeSection, setActiveSection] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      const sections = [
        "jamie-durie-presents",
        "immersive-animation",
        "photorealistic-renderings",
        "your-own-app",
        "interactive-virtual-tours",
      ];
      const currentSection = sections.find((section) => {
        const element = document.getElementById(section);
        if (element) {
          const rect = element.getBoundingClientRect();
          return rect.top <= 100 && rect.bottom >= 100;
        }
        return false;
      });

      setActiveSection(currentSection || "");
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Check initial position

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <nav className="fixed top-0 left-0 z-50  w-full hidden md:flex">
      <div className="flex w-full items-center justify-between overflow-x-auto [scrollbar-width:none] backdrop-blur-sm p-4">
        <div className="space-x-6 flex">
          <a
            href="#jamie-durie-presents"
            className={`whitespace-nowrap border-l-2 border-neutral-300/20 pl-2 text-xs cursor-pointer ${
              activeSection === "jamie-durie-presents" ? "text-[#c3aba4]" : ""
            }`}
          >
            Jamie Durie Presents
          </a>
          <a
            href="#immersive-animation"
            className={`whitespace-nowrap border-l-2 border-neutral-300/20 pl-2 text-xs cursor-pointer ${
              activeSection === "immersive-animation" ? "text-[#c3aba4]" : ""
            }`}
          >
            Immersive Animations
          </a>
          <a
            href="#interactive-virtual-tours"
            className={`whitespace-nowrap border-l-2 border-neutral-300/20 pl-2 text-xs cursor-pointer ${
              activeSection === "interactive-virtual-tours"
                ? "text-[#c3aba4]"
                : ""
            }`}
          >
            Interactive Virtual Tours
          </a>
          <a
            href="#photorealistic-renderings"
            className={`whitespace-nowrap border-l-2 border-neutral-300/20 pl-2 text-xs cursor-pointer ${
              activeSection === "photorealistic-renderings"
                ? "text-[#c3aba4]"
                : ""
            }`}
          >
            Photorealistic Renderings
          </a>
          <a
            href="#your-own-app"
            className={`whitespace-nowrap border-l-2 border-neutral-300/20 pl-2 text-xs cursor-pointer ${
              activeSection === "your-own-app" ? "text-[#c3aba4]" : ""
            }`}
          >
            Your own App
          </a>
        </div>
      </div>
    </nav>
  );
};
export default Navbar;
