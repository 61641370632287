import React, { useEffect, useState } from "react";

const VisionToLife = ({ setIsModalOpen, show, t, scrollToNextSection }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => setIsVisible(true), 80);
      return () => clearTimeout(timer);
    }
  }, [show]);

  return (
    <div
      className={`fade-in-section relative text-center ${
        isVisible ? "show" : ""
      }`}
    >
      <h1 className="header text-2xl md:text-4xl text-black tracking-widest text-center uppercase">
        {t("section2.header")}
      </h1>
      <div className="section-entry">
        <p className="text-p-s pt-8 pb-4 px-0 md:px-16">
          <i>
            <q>{t("section2.description")}</q>
          </i>
        </p>

        <p className="text-p-s pb-12">Jamie Durie</p>
      </div>

      {/* <Link to="/start-your-project" reloadDocument className="btn rounded-bl-xl rounded-tr-xl" id="vision-to-life">
        ENQUIRE NOW
      </Link> */}
      <div id="vision-to-life">
        <video
          autoPlay={true}
          playsInline={true}
          muted={true}
          controls={false}
          loop={true}
          style={{ height: "100%", width: "100%" }}
          poster={"/images/jamie.png"}
        >
          <source
            src={`${process.env.REACT_APP_BASE_URL}/theme/dashboard/JD_background.mp4`}
            type="video/mp4"
          />
        </video>
        <div
          className="scroll"
          onClick={() => scrollToNextSection("animation")}
        >
          <img
            src="/icons/down-arrow.svg"
            alt="Down Arrow"
            height={40}
            className="arrow-down"
          />
        </div>
      </div>
      <div className="intro vision-to-life-intro">
        <div
          className="flex-row-center mt-36 mb-6 cursor-pointer"
          onClick={() => setIsModalOpen(true)}
        >
          <img src="/icons/playbtn.svg" className="mr-2 playbtn" alt="" />
          <p className="border-white border-b-2 intro_title uppercase">
            {t("section2.mediaTitle")}
          </p>
        </div>
      </div>
      <p className="text-p-m mt-3 uppercase">{t("section2.label")}</p>
    </div>
  );
};

export default VisionToLife;
